<template>
	<attendance :id="item.participant_id" :status="item.appointment_status" :comments="item.comments"></attendance>
</template>

<script>
import Attendance from "@c/project/Attendance";

export default {
	name: "AttendanceCell",
	props: ["item", "column"],
	components: {
		Attendance
	},
};
</script>
